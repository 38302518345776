import { apiPostData } from '@/services/ApiService.js'
import store from '@/store'

const state = () => ({
  years: [],
  year: null,
  makes: [],
  make: '',
  make_id: null,
  models: [],
  model: '',
  model_id: null,
  versions: [],
  version: '',
  version_id: null,
  km: null,
  states: [],
  state_id: 0,
  cities: [],
  city_id: 0,
  name: '',
  mail: '',
  phone: '',
})

const actions = {
  /* Years */

  async loadYears({ commit, rootGetters }) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('mmvv/years/list', {
      count: rootGetters['getRetroactiveCounting'],
      nocache: false,
    }).then((response) => {
      commit('SET_YEARS', response)
    })
  },

  setYear({ commit, dispatch }, year) {
    commit('CLEAR_MAKES')
    commit('CLEAR_MODELS')
    commit('CLEAR_VERSIONS')
    commit('SET_YEAR', year)
    dispatch('loadMakes', year)
  },

  /* Makes */

  async loadMakes({ commit }, _year) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('mmvv/makes/list', {
      year: _year,
    }).then((response) => {
      commit('SET_MAKES', response)
    })
  },

  setMake({ commit, dispatch }, makeId) {
    commit('CLEAR_MODELS')
    commit('CLEAR_VERSIONS')
    commit('SET_MAKE', makeId)
    dispatch('loadModels', makeId)
  },

  /* Models */

  async loadModels({ commit, state }, makeId) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('mmvv/models/list', {
      year: state.year,
      make_id: makeId,
    }).then((response) => {
      commit('SET_MODELS', response)
    })
  },

  setModel({ commit, dispatch }, modelId) {
    commit('CLEAR_VERSIONS')
    commit('SET_MODEL', modelId)
    dispatch('loadVersions', modelId)
  },

  /* Versions */

  async loadVersions({ commit, state }, modelId) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('mmvv/versions/list', {
      year: state.year,
      model_id: modelId,
    }).then((response) => {
      commit('SET_VERSIONS', response)
    })
  },

  setVersion({ commit }, versionId) {
    commit('SET_VERSION', versionId)
  },

  /* Km */

  setKm({ commit }, km) {
    commit('SET_KM', km)
  },
}

const mutations = {
  /* Years */

  SET_YEARS(state, data) {
    state.years = data
  },

  SET_YEAR(state, data) {
    state.year = data
  },

  /* Makes */

  SET_MAKES(state, data) {
    state.makes = data
  },

  SET_MAKE(state, data) {
    state.make_id = data
    let make = state.makes.filter((_make) => {
      return _make.id == data
    })
    if (make.length) {
      state.make = 'name' in make[0] ? make[0].name : ''
    }
  },

  CLEAR_MAKES(state) {
    state.makes = []
    state.make_id = null
  },

  /* Models */

  SET_MODELS(state, data) {
    state.models = data
  },

  SET_MODEL(state, data) {
    state.model_id = data
    let model = state.models.filter((_model) => {
      return _model.id == data
    })

    if (model.length) {
      state.model = 'name' in model[0] ? model[0].name : ''
    }
  },

  CLEAR_MODELS(state) {
    state.models = []
    state.model_id = null
  },

  /* Versions */

  SET_VERSIONS(state, data) {
    state.versions = data
  },

  SET_VERSION(state, data) {
    state.version_id = data

    let version = state.versions.filter((_version) => {
      return _version.id == data
    })
    if (version.length) {
      state.version = 'name' in version[0] ? version[0].name : ''
    }
  },

  CLEAR_VERSIONS(state) {
    state.versions = []
    state.version_id = null
  },

  /* KM */

  SET_KM(state, km) {
    state.km = km
  },
}

const getters = {
  /* Years */

  getYears: (state) => {
    return state.years
  },

  getYear: (state) => {
    return state.year
  },

  /* Makes */

  getMakes: (state) => {
    return state.makes
  },

  hasMakes: (state) => {
    return Boolean(state.makes.length)
  },

  getMakeId: (state) => {
    return state.make_id
  },

  /* Models */

  getModels: (state) => {
    return state.models
  },

  hasModels: (state) => {
    return Boolean(state.models.length)
  },

  getModelId: (state) => {
    return state.model_id
  },

  /* Versions */

  getVersions: (state) => {
    return state.versions
  },

  hasVersions: (state) => {
    return Boolean(state.versions.length)
  },

  getVersionId: (state) => {
    return state.version_id
  },

  getVehicle: (state) => {
    return `${state.make} ${state.model} ${state.version}`
  },

  getMake: (state) => {
    return state.make
  },

  getModel: (state) => {
    return state.model
  },

  getVersion: (state) => {
    return state.version
  },

  /* Km */

  getKm: (state) => {
    return state.km
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
