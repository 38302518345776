import store from '@/store'
import axios from 'axios'

const domainsMap = new Map()

// Devel

domainsMap.set('front.dnmk-local.com.br:8583', {
  api: 'http://api.dnmk-local.com.br',
})

// Prod

domainsMap.set('dnmk.autoavaliar.com.br', {
  api: 'https://api-dnmk.autoavaliar.com.br',
})

const getDomainObj = function () {
  return domainsMap.get(window.location.host)
}

const getApiUrl = function () {
  let domain = getDomainObj()
  if (domain) return domain.api
}

const getAxiosInstance = function () {
  return axios.create({
    baseURL: getApiUrl(),
    withCredentials: true,
    headers: {
      'Api-Key': store.getters.getApiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
}

const responseHandler = function (response) {
  // window.parent.postMessage(
  //   {
  //     func: 'step-1',
  //     message: { name: 'teste', value: 15 },
  //   },
  //   '*'
  // )
  // Add toasts
  if ('toasts' in response.data) {
    store.dispatch('toaster/addNotifications', response.data.toasts)
  }

  // Error Markers
  if ('error_markers' in response.data) {
    if ('fields' in response.data.error_markers)
      store.dispatch('toaster/addFields', response.data.error_markers.fields)
    if ('pages' in response.data.error_markers)
      store.dispatch('toaster/addPages', response.data.error_markers.pages)
  }

  // Add Paginator Data
  if ('paginator' in response.data) {
    store.dispatch('paginator/setPaginatorData', response.data.paginator)
  }

  // Checagem de sessão expirada
  // if (!response.data.session.auth) {
  //   //store.dispatch('session/endSession')
  //   // store.dispatch('toaster/addNotification', {
  //   //   type: 'error',
  //   //   message:
  //   //     'Sentimos muito mas sua sessão expirou. Faça login novamente para utilizar a plataforma! ;)',
  //   //   ttl: 5000,
  //   // })
  // }
}

const errorsHandler = function (err) {
  store.dispatch('toaster/addNotification', {
    type: 'error',
    message: 'Infelizmente a API não respondeu corretamente!',
    ttl: 3000,
  })

  console.log(err)

  // if (err.response) {
  //   // The request was made and the server responded with a status code
  //   // that falls out of the range of 2xx

  //   console.log(err.response.data)
  //   console.log(err.response.status)
  //   console.log(err.response.headers)
  // } else if (err.request) {
  //   // The request was made but no response was received
  //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
  //   // http.ClientRequest in node.js

  //   console.log(err.request)
  // } else {
  //   // Something happened in setting up the request that triggered an Error

  //   console.log('Error', err.message)
  // }
  // console.log(err.config)
}

const apiPostData = function (url, body, getRow = false) {
  //store.dispatch('screen/setLoadingState', true)
  return getAxiosInstance()
    .post(url, body)
    .then((response) => {
      responseHandler(response)
      if (getRow) return response.data

      // console.log(response.data.cached)
      return response.data.data
    })
    .catch((err) => {
      errorsHandler(err)
    })
    .finally(() => {
      store.dispatch('screen/setLoadingState', false)
    })
}

const apiGetData = function (url, getRow = false) {
  //store.dispatch('screen/setLoadingState', true)
  return getAxiosInstance()
    .get(url)
    .then((response) => {
      responseHandler(response)

      if (getRow) return response.data
      return response.data.data
    })
    .catch((err) => {
      errorsHandler(err)
    })
    .finally(() => {
      //store.dispatch('screen/setLoadingState', false)
    })
}

export { apiPostData, apiGetData }
