const state = () => ({
  name: '',
  mail: '',
  phone: '',
})

const actions = {
  /* Personal */

  setName({ commit }, name) {
    commit('SET_NAME', name)
  },

  setMail({ commit }, mail) {
    commit('SET_MAIL', mail)
  },

  setPhone({ commit }, phone) {
    commit('SET_PHONE', phone)
  },
}

const mutations = {
  /* Personal */

  SET_NAME(state, name) {
    state.name = name
  },

  SET_MAIL(state, mail) {
    state.mail = mail
  },

  SET_PHONE(state, phone) {
    state.phone = phone
  },
}

const getters = {
  /* Personal */

  getName: (state) => {
    return state.name
  },

  getMail: (state) => {
    return state.mail
  },

  getPhone: (state) => {
    return state.phone
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
