import { apiPostData } from '@/services/ApiService.js'
import store from '@/store'

const state = () => ({
  states: [],
  state_id: null,
  cities: [],
  city_id: null,
})

const actions = {
  /* States */

  async loadStates({ commit }) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('geo/states/list', {}).then((response) => {
      commit('SET_STATES', response)
    })
  },

  setState({ commit, dispatch }, stateId) {
    commit('CLEAR_CITIES')
    commit('SET_STATE_ID', stateId)
    dispatch('loadCities', stateId)
  },

  /* Cities */

  async loadCities({ commit }, stateId) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('geo/cities/list', { state_id: stateId }).then(
      (response) => {
        commit('SET_CITIES', response)
      }
    )
  },

  setCity({ commit }, cityId) {
    commit('SET_CITY_ID', cityId)
  },
}

const mutations = {
  /* States */

  SET_STATES(state, states) {
    state.states = states
  },

  SET_STATE_ID(state, stateId) {
    state.state_id = stateId
  },

  /* Cities */

  SET_CITIES(state, cities) {
    state.cities = cities
  },

  SET_CITY_ID(state, cityId) {
    state.city_id = cityId
  },

  CLEAR_CITIES(state) {
    state.cities = []
    state.city_id = null
  },
}

const getters = {
  /* States */

  getStates: (state) => {
    return state.states
  },

  hasStates: (state) => {
    return Boolean(state.states.length)
  },

  getStateId: (state) => {
    return state.state_id
  },

  getState: (state) => {
    let result = state.states.find((_state) => {
      return _state.id == state.state_id
    })

    if (result == undefined) return ''

    return 'name' in result ? result.name : ''
  },

  /* Cities */

  getCities: (state) => {
    return state.cities
  },

  hasCities: (state) => {
    return Boolean(state.cities.length)
  },

  getCityId: (state) => {
    return state.city_id
  },

  getCity: (state) => {
    let result = state.cities.find((_city) => {
      return _city.id == state.city_id
    })

    if (result == undefined) return ''
    return 'name' in result ? result.name : ''
  },

  getLocalization: (state, getters) => {
    return `${getters.getState} - ${getters.getCity}`
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
