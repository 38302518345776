export const INCREMENT_UPDATE_REQUESTS = (state) => {
  state.updatePending++
}

export const SET_API_KEY = (state, apiKey) => {
  state.apiKey = apiKey
}

export const SET_BOOTSTRAP_DATA = (state, data) => {
  if ('modus' in data) state.bootstrap.modus = data.modus

  if ('retroactive_counting' in data)
    state.bootstrap.retroactive_counting = data.retroactive_counting

  if ('origin' in data) state.bootstrap.origin = data.origin

  if ('look' in data) {
    if ('intial_color' in data.look)
      state.bootstrap.look.intial_color = data.look.intial_color

    if ('final_color' in data.look)
      state.bootstrap.look.final_color = data.look.final_color

    if ('logo_header' in data.look)
      state.bootstrap.look.logo_header = data.look.logo_header

    if ('final_color' in data.look)
      state.bootstrap.look.logo_footer = data.look.logo_footer
  }

  if ('links' in data) {
    if ('logo_header' in data.links)
      state.bootstrap.links.logo_header = data.links.logo_header

    if ('logo_footer' in data.links)
      state.bootstrap.links.logo_footer = data.links.logo_footer

    if ('header' in data.links) state.bootstrap.links.header = data.links.header

    if ('footer' in data.links) state.bootstrap.links.footer = data.links.footer
  }

  if ('texts' in data) {
    if ('general_title' in data.texts)
      state.bootstrap.texts.general_title = data.texts.general_title

    if ('general_sub' in data.texts)
      state.bootstrap.texts.general_sub = data.texts.general_sub

    if ('general_copyright' in data.texts)
      state.bootstrap.texts.general_copyright = data.texts.general_copyright

    if ('leads_legal' in data.texts)
      state.bootstrap.texts.leads_legal = data.texts.leads_legal

    if ('final_success' in data.texts)
      state.bootstrap.texts.final_success = data.texts.final_success

    if ('final_fail' in data.texts)
      state.bootstrap.texts.final_fail = data.texts.final_fail

    if ('texts_scheduler' in data.texts)
      state.bootstrap.texts.texts_scheduler = data.texts.texts_scheduler
  }
}
