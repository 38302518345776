import { apiPostData } from '@/services/ApiService.js'
import store from '@/store'

const state = () => ({
  fields: [],
})

const actions = {
  async load({ commit }) {
    store.dispatch('screen/setLoadingState', true)
    await apiPostData('config/bootstrap/get-custom-rules', {}).then(
      (response) => {
        commit('SET_CUSTOM_FIELDS', response)
      }
    )
  },
  answer({ commit }, _answer) {
    commit('SET_ANSWER', _answer)
  },
}

const mutations = {
  SET_CUSTOM_FIELDS(state, response) {
    state.fields = response
  },
  SET_ANSWER(state, _answer) {
    let custom = state.fields.find((f) => f.field == _answer.field)
    if (custom) custom.answer = _answer.value
  },
}

const getters = {
  getFieldsCount: (state) => {
    return state.fields.length
  },
  getFields: (state) => {
    return state.fields
  },
  hasAnswersNull: (state) => {
    return (
      state.fields.filter((f) => f.answer === null || f.answer === '').length >
      0
    )
  },
  getAnswers: (state) => {
    let answers = {}
    state.fields.forEach(function (custom, index) {
      if (custom.answer == null) {
        answers[custom.field] = null
      } else {
        answers[custom.field] =
          custom.kind == 'CURRENCY'
            ? custom.answer.replaceAll('.', '').replaceAll(',', '.')
            : custom.answer
      }
    })
    return answers
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
