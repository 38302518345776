import { createStore } from 'vuex'

import * as getters from '@/store/getters'
import * as actions from '@/store/actions.js'
import * as mutations from '@/store/mutations'

import genericColection from '@/store/modules/genericColection.js'
import toaster from '@/store/modules/toaster.js'
import screen from '@/store/modules/screen.js'
import vehicle from '@/store/modules/vehicle.js'
import personal from '@/store/modules/personal.js'
import geo from '@/store/modules/geo.js'
import price from '@/store/modules/price.js'
import customFields from '@/store/modules/customFields.js'
import scheduler from '@/store/modules/scheduler.js'

const state = {
  updatePending: 0,
  apiKey: '',
  bootstrap: {
    origin: 'front',
    modus: 'single_page',
    retroactive_counting: 10,
    look: {
      intial_color: '#fff',
      final_color: '#fff',
      logo_header: 'https://dnmk.autoavaliar.com.br/img/dinamika-branco.png',
      logo_footer:
        'https://dnmk.autoavaliar.com.br/img/dinamika-vertical-gradiente.png',
    },
    links: {
      header: [],
      footer: [],
      logo_header: '-',
      logo_footer: '-',
    },
    texts: {
      general_title: '-',
      general_sub: '-',
      general_copyright: '-',
      leads_legal: '-',
      final_success: '-',
      final_fail: '-',
      texts_scheduler: '-',
    },
  },
}

export default createStore({
  modules: {
    genericColection,
    toaster,
    screen,
    vehicle,
    personal,
    geo,
    price,
    customFields,
    scheduler,
  },
  state,
  getters,
  mutations,
  actions,
})
