import { apiPostData } from '@/services/ApiService.js'
import store from '@/store'

const state = () => ({
  times: [
    '9h  às 10h',
    '10h às 11h',
    '11h às 12h',
    '13h às 14h',
    '14h às 15h',
    '15h às 16h',
    '16h às 17h',
  ],
  time: null,
  date: null,
})

const actions = {
  setTime({ commit }, time) {
    commit('SET_TIME', time)
  },
  setDate({ commit }, date) {
    commit('SET_DATE', date)
  },

  async confirm({ commit, rootGetters, getters }) {
    store.dispatch('screen/setLoadingState', true)

    window.parent.postMessage(
      {
        func: 'leads/lead/schedule',
        message: {
          client_name: rootGetters['personal/getName'],
          client_email: rootGetters['personal/getMail'],
          client_phone: rootGetters['personal/getPhone'],
          vehicle_name: rootGetters['vehicle/getVehicle'],
          date_scheduled: getters['getDate'],
          hour_scheduled: getters['getTime'],
        },
      },
      '*'
    )

    await apiPostData('leads/lead/schedule', {
      client_name: rootGetters['personal/getName'],
      client_email: rootGetters['personal/getMail'],
      client_phone: rootGetters['personal/getPhone'],
      vehicle_name: rootGetters['vehicle/getVehicle'],
      date_scheduled: getters['getDate'],
      hour_scheduled: getters['getTime'],
    }).then((response) => {
      store.dispatch('screen/goToNext')
    })
  },
}

const mutations = {
  SET_TIME(state, time) {
    state.time = time
  },
  SET_DATE(state, date) {
    state.date = date
  },
}

const getters = {
  getTimes: (state) => {
    return state.times
  },
  getTime: (state) => {
    return state.time
  },
  getDate: (state) => {
    return state.date
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
