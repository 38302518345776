<template>
  <router-view />
  <g-dialog-root />
</template>

<script>
import { GDialogRoot } from 'gitart-vue-dialog'

export default {
  components: {
    GDialogRoot,
  },
  setup() {
    console.log('config')
  },
}
</script>

<style></style>
