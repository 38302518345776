const state = () => ({
  loadingState: false,
  step: 1,
  maxStep: 8,
})

const actions = {
  goToNext({ commit, rootGetters }) {
    commit('SET_NEXT_STEP', rootGetters['customFields/getFieldsCount'])
  },
  goToPrior({ commit, rootGetters }) {
    commit('SET_PRIOR_STEP', rootGetters['customFields/getFieldsCount'])
  },
  clearSteps({ commit }) {
    commit('CLEAR_STEPS')
  },
  setLoadingState({ commit }, status) {
    commit('SET_LOADING_STATE', status)
  },
}

const mutations = {
  SET_NEXT_STEP(state, fieldsCount) {
    if (state.step == 2 && fieldsCount === 0) {
      state.step++
    }
    if (state.step < state.maxStep) state.step++
  },

  SET_PRIOR_STEP(state, fieldsCount) {
    if (state.step == 4 && fieldsCount === 0) {
      state.step--
    }
    if (state.step > 1) state.step--
  },

  SET_LOADING_STATE(state, status) {
    state.loadingState = status
  },

  CLEAR_STEPS(state) {
    state.step = 1
  },
}

const getters = {
  getLoadingState: (state) => {
    return state.loadingState
  },
  getStep: (state) => {
    return state.step
  },

  getMaxStep: (state) => {
    return state.maxStep
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
