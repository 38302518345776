import { apiPostData } from '@/services/ApiService.js'
import store from '@/store'

const state = () => ({
  price: 0,
  fipePrice: 0,
  aaPrice: 0,
  flags: [],
})

const actions = {
  async loadPrice({ commit, rootGetters }) {
    store.dispatch('screen/setLoadingState', true)
    let request = {
      version_id: rootGetters['vehicle/getVersionId'],
      vehicle: rootGetters['vehicle/getVehicle'],
      year: rootGetters['vehicle/getYear'],
      mileage: rootGetters['vehicle/getKm'],

      localization: rootGetters['geo/getLocalization'],
      state_id: rootGetters['geo/getStateId'],
      city_id: rootGetters['geo/getCityId'],

      name: rootGetters['personal/getName'],
      mail: rootGetters['personal/getMail'],
      phone: rootGetters['personal/getPhone'],

      sp_fields: rootGetters['customFields/getAnswers'],
    }

    window.parent.postMessage(
      {
        func: 'smartprice/calculate-request',
        message: request,
      },
      '*'
    )

    await apiPostData('smartprice/calculate/get', request).then((response) => {
      //console.log(response)
      commit('SET_PRICE', response)
    })
  },
}

const mutations = {
  SET_PRICE(state, response) {
    window.parent.postMessage(
      {
        func: 'smartprice/calculate-response',
        message: response,
      },
      '*'
    )

    state.price = response.price
    state.fipePrice = response.fipePrice
    state.aaPrice = response.aaPrice
    state.flags = response.flags
  },
}

const getters = {
  getPrice: (state) => {
    return state.price
  },

  getFipePrice: (state) => {
    return state.fipePrice
  },

  getAaPrice: (state) => {
    return state.aaPrice
  },

  getFlags: (state) => {
    return state.flags
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
