export const getUpdatePendingCount = (state) => {
  return state.updatePending
}

export const getApiKey = (state) => {
  return state.apiKey
}

export const recentHistory = (state) => {
  return state.history.slice(-5).join(', ')
}

export const getOrigin = (state) => {
  return state.bootstrap.origin
}

export const getModus = (state) => {
  return state.bootstrap.modus
}

export const getRetroactiveCounting = (state) => {
  return state.bootstrap.retroactive_counting
}

export const getIntialColor = (state) => {
  return state.bootstrap.look.intial_color
}

export const getFinalColor = (state) => {
  return state.bootstrap.look.final_color
}

export const getGradient = (state) => (deg, ini, end) => {
  return `background: linear-gradient( ${deg}deg, ${state.bootstrap.look.intial_color} ${ini}%, ${state.bootstrap.look.final_color} ${end}% );`
}

export const getTextGradient = (state) => (deg, pct) => {
  return `background: -webkit-linear-gradient(${deg}deg,  ${state.bootstrap.look.intial_color}, ${state.bootstrap.look.final_color} ${pct}%);
  -webkit-background-clip: text; -webkit-text-fill-color: transparent;`
}

export const getLogoHeader = (state) => {
  return state.bootstrap.look.logo_header
}

export const getLogoFooter = (state) => {
  return state.bootstrap.look.logo_footer
}

export const getHeaderLinks = (state) => {
  return state.bootstrap.links.header
}

export const getFooterLinks = (state) => {
  return state.bootstrap.links.footer
}

export const getHeaderLink = (state) => {
  return state.bootstrap.links.logo_header
}

export const getFooterLink = (state) => {
  return state.bootstrap.links.logo_footer
}

export const getTextGeneralTitle = (state) => {
  return state.bootstrap.texts.general_title
}

export const getTextGeneralSub = (state) => {
  return state.bootstrap.texts.general_sub
}

export const getTextGeneralCopyright = (state) => {
  return state.bootstrap.texts.general_copyright
}

export const getTextLeadsLegal = (state) => {
  return state.bootstrap.texts.leads_legal
}

export const getTextFinalSuccess = (state) => {
  return state.bootstrap.texts.final_success
}

export const getTextFinalFail = (state) => {
  return state.bootstrap.texts.final_fail
}

export const getTextsScheduler = (state) => {
  return state.bootstrap.texts.texts_scheduler
}
