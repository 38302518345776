import { apiGetData } from '@/services/ApiService.js'

export const addUpdateRequest = ({ commit }) => {
  commit('INCREMENT_UPDATE_REQUESTS')
}

export const setApiKey = ({ commit }, apiKey) => {
  commit('SET_API_KEY', apiKey)
}

export async function getConfig({ commit, rootGetters }) {
  if (rootGetters['getOrigin'] == 'api') return 0

  await apiGetData('config/bootstrap/get-config').then((response) => {
    commit('SET_BOOTSTRAP_DATA', response)
  })
}
