import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/:apiKey',
    props: (route) => ({
      apiKey: route.params.apiKey || '',
    }),
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/layout/PublicView.vue'
      ),
    children: [
      {
        path: '',
        redirect: { name: 'QueryPrice' },
      },
      {
        path: 'precify',
        name: 'QueryPrice',
        meta: { loadConfig: true, loadCustomFields: true },
        component: () =>
          import(/* webpackChunkName: "AView" */ '@/views/app/PrecifyView.vue'),
      },
    ],
  },

  {
    path: '/error',
    name: 'Errors',
    component: () =>
      import(
        /* webpackChunkName: "PublicLayout" */ '@/views/layout/PublicView.vue'
      ),

    children: [
      {
        path: '503',
        name: 'Error503',
        component: () =>
          import(
            /* webpackChunkName: "Error503" */ '@/views/errors/503View.vue'
          ),
      },
      {
        path: '404',
        name: 'Error404',
        component: () =>
          import(
            /* webpackChunkName: "Error404" */ '@/views/errors/404View.vue'
          ),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'Error404' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }

    if (savedPosition) {
      return savedPosition
    }
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  store.dispatch('screen/setLoadingState', true)
  store.dispatch('setApiKey', to.params.apiKey)
  next()
})

router.afterEach((to, from) => {
  let counter = 0
  if (to.meta.loadConfig) {
    counter++
    store.dispatch('screen/setLoadingState', true)
    store.dispatch('getConfig').then((res) => {
      if (to.meta.loadCustomFields) {
        counter++
        store.dispatch('customFields/load').then((res) => {
          store.dispatch('screen/setLoadingState', false)
        })
      } else {
        store.dispatch('screen/setLoadingState', false)
      }
    })
  }

  if (counter === 0) {
    store.dispatch('screen/setLoadingState', false)
  }
})

export default router
